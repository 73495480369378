import { IconAlertOctagon } from '@tabler/icons-react'
import Card from '~/components/Card/Card'
import { Link } from '~/components/Link'
import Typography, {
  TypographyTag,
  TypographyVariant,
} from '~/components/Typography'
import { SupersededProductCardProps } from './SupersededProductCard.types'

export const SupersededProductCard = ({
  products,
}: SupersededProductCardProps) => {
  return (
    <Card
      dataId='notifyWhenInStockButton'
      title='Superseded Product'
      variant='warning'
      icon={<IconAlertOctagon size={24} />}>
      <Typography
        tag={TypographyTag.span}
        variant={TypographyVariant.BodyRegular}
        className='text-primary'>
        This product has been replaced by{' '}
        {products.map((product, index) => {
          return (
            <span key={product.sku}>
              <Link
                href={product.url}
                className='underline'
                target='_blank'
                rel='noopener noreferrer'>
                {product.sku}
              </Link>
              {/* Add a comma and space except after the last product */}
              {index < products.length - 1 && ', '}
            </span>
          )
        })}
        . Click for the replacement products.
      </Typography>
    </Card>
  )
}
